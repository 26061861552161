/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader';

import './AmastyMoreInformation.style';

/** @namespace Scandiweb/AmastyRma/Component/AmastyMoreInformation/Component */
export class AmastyMoreInformationComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        returnInfo: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
        })).isRequired
    };

    renderContent() {
        const {
            returnInfo
        } = this.props;

        return (
            <p className="amrma-more-information">
                { returnInfo.map(this.renderReturnInfo) }
            </p>
        );
    }

    renderReturnInfo = (info) => {
        const {
            label,
            value
        } = info;

        return (
            <>
                <strong>
                    { `${label}: ` }
                </strong>
                { value }
            </>
        );
    };

    render() {
        const {
            isLoading
        } = this.props;

        return null;

        if (isLoading) {
            return <Loader isLoading />;
        }

        return (
            <ContentWrapper
              label="More information"
              mix={ { block: 'MoreInformation' } }
              wrapperMix={ { block: 'MoreInformation', elem: 'Wrapper' } }
            >
                { this.renderContent() }
            </ContentWrapper>
        );
    }
}

export default AmastyMoreInformationComponent;
