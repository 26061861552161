/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { convertToObjectFromEntries } from './Utils';

/** @namespace Scandiweb/AmastyRma/Util/MyAccount/addNewTabToTabsMap */
export const addNewTabToTabsMap = (tabs, tabToAdd) => {
    const { section: sectionToAdd } = Object.values(tabToAdd)[0];
    // eslint-disable-next-line fp/no-let
    let newTabs = tabs;

    Object.entries(tabs).forEach(([, { section: currentSection }], idx) => {
        if (sectionToAdd + 1 === currentSection) {
            const before = convertToObjectFromEntries(Object.entries(tabs).slice(0, idx));
            const after = convertToObjectFromEntries(Object.entries(tabs).slice(idx));

            newTabs = { ...before, ...tabToAdd, ...after };
        }
    });

    if (Object.entries(newTabs).length === Object.entries(tabs).length) {
        return {
            ...tabs,
            ...tabToAdd
        };
    }

    return newTabs;
};
