/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Form from 'Component/Form';
import Html from 'Component/Html';
import Loader from 'Component/Loader';
import Popup from 'Component/Popup';

import AmastyChat from '../../component/AmastyChat';
import AmastyCustomerInfo from '../../component/AmastyCustomerInfo';
import {
    AmRmaRequestMessagesType,
    AmRmaReturnByIdType,
    AmRmaSettingsViewReturnType,
    CustomerType,
    RefType
} from '../../type/AmastyRMA.type';
import {
    ID_POPUP_INFO,
    STATUS_APPROVED, STATUS_COMPLETED, STATUS_DELIVERED, STATUS_PROCESSING
} from './AmastyViewReturn.config';

import './AmastyViewReturn.style';

/** @namespace Scandiweb/AmastyRma/Route/AmastyViewReturn/Component */
export class AmastyViewReturnComponent extends PureComponent {
    static defaultProps = {
        popupMessage: ''
    };

    static propTypes = {
        requestHash: PropTypes.string.isRequired,
        customer: CustomerType.isRequired,
        getAmRmaRequestMessages: AmRmaRequestMessagesType.isRequired,
        getAmRmaReturnById: AmRmaReturnByIdType.isRequired,
        getAmRmaReturnInstruction: PropTypes.string.isRequired,
        getAmRmaSettings: AmRmaSettingsViewReturnType.isRequired,
        cancelRMA: PropTypes.func.isRequired,
        submitRating: PropTypes.func.isRequired,
        changeCheckboxState: PropTypes.func.isRequired,
        changeCurrentRating: PropTypes.func.isRequired,
        getRequestIncrementId: PropTypes.func.isRequired,
        getProductData: PropTypes.func.isRequired,
        checkboxesTriggers: PropTypes.objectOf(PropTypes.bool).isRequired,
        returnSteps: PropTypes.arrayOf(PropTypes.string).isRequired,
        popupMessage: PropTypes.string,
        isLoading: PropTypes.bool.isRequired,
        isCancelButtonDisabled: PropTypes.bool.isRequired,
        isRequestRated: PropTypes.bool.isRequired,
        isRatingButtonDisabled: PropTypes.bool.isRequired,
        currentRating: PropTypes.number.isRequired,
        requestCustomFields: PropTypes.objectOf(PropTypes.string).isRequired,
        nonEmptyCustomFields: PropTypes.number.isRequired,
        ratingCommentInput: RefType.isRequired
    };

    renderContent() {
        const {
            getAmRmaReturnById: {
                request_id,
                status: {
                    color: statusColor,
                    label: statusLabel
                },
                request_items
            },
            getRequestIncrementId
        } = this.props;
         
        let isItemsDamagedRMA = 0;
        for (var i = 0; i < request_items.length; i++) {   
			    if(request_items[i].reason_id==7){ /* item damaged */
					isItemsDamagedRMA = 1;
				}			   
		}
		
		if(isItemsDamagedRMA==1) {
			return (
				<div className="amrma-main-container">
					<div className="amrma-header-block">
						<div className="amrma-return-number">
							<h1 className="amrma-header">
								{ __('Return ') }
								{ getRequestIncrementId(request_id) }
							</h1>
							<span className="amrma-status" style={ { backgroundColor: statusColor } }>{ statusLabel }</span>
						</div>
						{ this.renderIsCancelable() }
					</div>
					 
					{ this.renderCustomerInfo() }
					{ this.renderCustomFieldsBlock() }
					 
					<div className="amrma-table-container">
						<h2 className="amrma-title">{ __('Items') }</h2>
						<div className='Tablecommon'>
							<div className='Tablecommon-overflowscroll'>
								<table>
									<thead>
										<tr>
											<th scope="col" className="shipping">{ __('Product Details') }</th>
											<th scope="col" className="id">{ __('Return Details') }</th>
											<th scope="col" className="status">{ __('Item Status') }</th>
											<th scope="col" className="empty">&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										{ request_items.map(this.renderRequestItemBlock) }
									</tbody>
								</table>
							</div>
						</div>
					</div> 
				</div>
			);
		}

        return (
            <div className="amrma-main-container">
                <div className="amrma-header-block">
                    <div className="amrma-return-number">
                        <h1 className="amrma-header">
                            { __('Return ') }
                            { getRequestIncrementId(request_id) }
                        </h1>
                        <span className="amrma-status" style={ { backgroundColor: statusColor } }>{ statusLabel }</span>
                    </div>
                    { this.renderIsCancelable() }
                </div>
                { this.renderRequestStatusBlock() }
                { this.renderFeedbackBlock() }
                { this.renderCustomerInfo() }
                { this.renderCustomFieldsBlock() }
                { this.renderReturnInstructionsBlock() }
                <div className="amrma-table-container">
                    <h2 className="amrma-title">{ __('Items') }</h2>
                    <div className='Tablecommon'>
                        <div className='Tablecommon-overflowscroll'>
                            <table>
                                <thead>
                                    <tr>
                                        <th scope="col" className="shipping">{ __('Product Details') }</th>
                                        <th scope="col" className="id">{ __('Return Details') }</th>
                                        <th scope="col" className="status">{ __('Item Status') }</th>
                                        <th scope="col" className="empty">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { request_items.map(this.renderRequestItemBlock) }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                { this.renderChatBlock() }
                <div id="tracking-number" />
                { this.renderPopup() }
            </div>
        );
    }

    renderRequestStatusBlock() {
        const {
            getAmRmaReturnById: {
                status: {
                    state: statusState
                }
            },
            returnSteps
        } = this.props;

        return (
            <div className="amrma-status-container">
                <div className="amrma-progress">
                    <div className={ `amrma-percent -status-${statusState}` } />
                </div>
                <div className="amrma-steps">
                    <div
                      className={ `amrma-step -pending ${returnSteps[STATUS_PROCESSING]}` }
                      id="0"
                      data-number="1"
                      data-label={ `1. ${__('Processing')}` }
                    >
                        <span className="amrma-name">
                            1.
                            { ' ' }
                            { __('Processing') }
                        </span>
                    </div>
                    <div
                      className={ `amrma-step -authorized ${returnSteps[STATUS_APPROVED]}` }
                      id="1"
                      data-number="2"
                      data-label={ `2. ${__('Approved')}` }
                    >
                        <span className="amrma-name">
                            2.
                            { ' ' }
                            { __('Approved') }
                        </span>
                    </div>
                    <div
                      className={ `amrma-step -received ${returnSteps[STATUS_DELIVERED]}` }
                      id="2"
                      data-number="3"
                      data-label={ `3. ${__('Delivered')}` }
                    >
                        <span className="amrma-name">
                            3.
                            { ' ' }
                            { __('Delivered') }
                        </span>
                    </div>
                    <div
                      className={ `amrma-step -resolved ${returnSteps[STATUS_COMPLETED]}` }
                      id="3"
                      data-number="4"
                      data-label={ `4. ${__('Completed')}` }
                    >
                        <span className="amrma-name">
                            4.
                            { ' ' }
                            { __('Completed') }
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    renderCustomerInfo() {
        const {
            getAmRmaReturnById: {
                order_increment_id,
                created_at
            },
            customer
        } = this.props;

        return (
            <AmastyCustomerInfo
              isNewReturn={ false }
              orderIncrementId={ order_increment_id }
              returnCreatedAt={ created_at }
              customer={ customer }
            />
        );
    }

    renderIsCancelable() {
        const {
            getAmRmaReturnById: {
                status: {
                    state: statusState
                }
            },
            isCancelButtonDisabled,
            cancelRMA
        } = this.props;

        if (statusState !== 0) {
            return null;
        }

        return (
            <button
                block="Button"            
                mods={ { capitalize: true, shadow:true } }
                mix={ { block: 'amrma', elem: 'cancel' } }
                onClick={ cancelRMA }
                disabled={ isCancelButtonDisabled }
            >
                { __('Cancel RMA') }
            </button>
        );
    }

    renderFeedbackBlock() {
        const {
            getAmRmaSettings: {
                isAskForFeedback

            },
            getAmRmaReturnById: {
                rating,
                status: {
                    state: statusState
                },
                url_hash
            },
            submitRating,
            isRequestRated,
            isRatingButtonDisabled,
            ratingCommentInput
        } = this.props;

        if (
            isAskForFeedback
            && !rating
            && statusState === STATUS_COMPLETED
            && isRequestRated !== true
        ) {
            return (
                <div>
                    <div className="amrma-stars-container">
                        <Form onSubmit={ submitRating }>
                            <input type="hidden" name="hash" value={ url_hash } />
                            <div className="amrma-rating-block">
                                <p className="amrma-title">{ __('How do you rate the work of a manager?') }</p>
                                <div className="stars-container">
                                    { Array.from({ length: 5 }, this.renderStarsBlock) }
                                </div>
                            </div>
                            <div className="amrma-feedback-container">
                                <input
                                  ref={ ratingCommentInput }
                                  type="text"
                                  className="amrma-feedback"
                                  name="rating_comment"
                                  placeholder={ __('You can also leave a comment.') }
                                />
                                <button
                                  block="Button"            
                                  mods={ { shadow: true, capitalize: true } }
                                  mix={ { block: 'MyAccountMyWishlist', elem: 'ShareWishlistButton' } }
                                  title={ __('Send') }
                                  type="submit"
                                  disabled={ isRatingButtonDisabled }
                                >
                                    { __('Send') }

                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            );
        }

        if (isRequestRated === true) {
            return (
                <div className="amrma-stars-container">
                    <div className="rated-message amrma-title">
                        { __('Thank you for your feedback.') }
                    </div>
                </div>
            );
        }

        return null;
    }

    renderStarsBlock = (_, i) => {
        const {
            currentRating,
            changeCurrentRating
        } = this.props;

        return (
            <input
              type="radio"
              className={ `star ${currentRating >= (i + 1) ? 'full-star' : 'hollow-star'}` }
              onMouseEnter={ changeCurrentRating }
              onMouseLeave={ changeCurrentRating }
              onClick={ changeCurrentRating }
              data-element-rating={ i + 1 }
            />
        );
    };

    renderCustomFieldsElements = ({ code, title }) => {
        const {
            requestCustomFields
        } = this.props;

        return (
            <p className="_nomargin" key={ code }>
                <span className="_strong">
                    { `${title}: ` }
                </span>
                <span className="amrma-name">{ requestCustomFields[code] }</span>
            </p>
        );
    };

    renderCustomFieldsBlock() {
        const {
            getAmRmaSettings: {
                customFields: settingsCustomFields,
                customFieldsBlockTitle
            },
            nonEmptyCustomFields
        } = this.props;

        if (nonEmptyCustomFields === 0) {
            return '';
        }

        return (
            <div className="amrma-bank-details">
                <h2 className="amrma-title">
                    { customFieldsBlockTitle }
                </h2>
                { settingsCustomFields.map(this.renderCustomFieldsElements) }
            </div>
        );
    }

    renderReturnInstructionsBlock() {
        const {
            getAmRmaReturnInstruction = '',
            changeCheckboxState,
            checkboxesTriggers
        } = this.props;
        const triggerId = 'amrma-instruction-block';

        if (!getAmRmaReturnInstruction?.length) {
            return null;
        }

        return (
            <div className="amrma-return-instructions">
                <input
                  type="checkbox"
                  id={ triggerId }
                  className="amrma-instructions"
                  onChange={ changeCheckboxState }
                />
                <label htmlFor={ triggerId } className="amrma-instructions-title">
                    <h2 className="amrma-title">{ __('Returns: how it works') }</h2>
                    <div className="amrma-show" />
                </label>
                <div className="amrma-instruction-block" hidden={ !checkboxesTriggers[triggerId] }>
                    <Html content={ getAmRmaReturnInstruction } />
                </div>
            </div>
        );
    }

    renderRequestItemBlock = (
        {
            product_url_key: request_item_url_key,
            reason_id: request_reason_id,
            condition_id: request_condition_id,
            resolution_id: request_resolution_id,
            product_name,
            request_qty,
            item_status
        }
    ) => {
        const {
            getProductData
        } = this.props;

        const {
            productSku,
            productImage,
            reason,
            conditionLabel,
            resolutionLabel
        } = getProductData({
            request_item_url_key,
            request_reason_id,
            request_condition_id,
            request_resolution_id
        });

        return (
            <tr className="amrma-row">
                <td data-th="" className="col item">
                    <div className="amrma-image"><img src={ productImage } alt={ product_name } /></div>
                </td>
                <td data-th={ __('Product Details') } className="col amrma-details">
                    <div className="amrma-product-information">
                        <strong className="amrma-product-name">
                            { product_name }
                        </strong>
                        <p className="_nomargin">
                            <strong>
                                { __('SKU') }
                                :
                                { ' ' }
                            </strong>
                            { productSku }
                        </p>
                        <p className="_nomargin">
                            <strong>
                                { __('Qty') }
                                :
                                { ' ' }
                            </strong>
                            { request_qty }
                        </p>
                    </div>
                </td>
                <td data-th={ __('RMA Details') } className="col details">
                    <p className="_nomargin">
                        <strong>
                            { __('Return Reason') }
                            :
                            { ' ' }
                        </strong>
                        { reason.reasonLabel }
                    </p>
                    <p className="_nomargin">
                        <strong>
                            { __('Items Conditions') }
                            :
                            { ' ' }
                        </strong>
                        { conditionLabel }
                    </p>
                    <p className="_nomargin">
                        <strong>
                            { __('Return Resolution') }
                            :
                            { ' ' }
                        </strong>
                        { resolutionLabel }
                    </p>
                    <br />
                    <p className="_nomargin">
                        <strong>
                            { reason.reasonPayer }
                        </strong>
                    </p>
                </td>
                <td data-th={ __('Status') } className="col status">
                    <span className={ `amrma-status -${item_status.toLowerCase()}` }>
                        { item_status }
                    </span>
                </td>
            </tr>
        );
    };

    renderChatBlock() {
        const {
            getAmRmaRequestMessages,
            requestHash
        } = this.props;

        return (
            <AmastyChat
              messages={ getAmRmaRequestMessages }
              requestHash={ requestHash }
            />
        );
    }

    renderPopup() {
        const {
            popupMessage
        } = this.props;

        return (
            <div>
                <Popup
                  id={ ID_POPUP_INFO }
                >
                    <p style={ { whiteSpace: 'pre-wrap' } }>
                        { popupMessage }
                    </p>
                </Popup>
            </div>
        );
    }

    render() {
        const {
            isLoading
        } = this.props;

        if (isLoading) {
            return (
                <Loader isLoading />
            );
        }

        return (
            <div block="AmastyViewReturn">
                { this.renderContent() }
            </div>
        );
    }
}

export default AmastyViewReturnComponent;
