/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './AddIcon.style';

/** @namespace Component/AddIcon/Component */
export class AddIcon extends PureComponent {
    static propTypes = {
        isPrimary: PropTypes.bool
    };

    static defaultProps = {
        isPrimary: false
    };

    render() {
        const { isPrimary } = this.props;

        return (
            <svg
              block="AddIcon"
              mods={ { isPrimary } }
              width="21"
              height="22"
              viewBox="0 0 21 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_3205_17086)">
            <path d="M0.702862 11.4603C0.60308 11.3601 0.535286 11.2326 0.508057 11.0939C0.480829 10.9552 0.495387 10.8115 0.549891 10.6811C0.604395 10.5507 0.696396 10.4393 0.814257 10.3611C0.932118 10.2829 1.07054 10.2414 1.21203 10.2418L19.9047 10.294C20.0944 10.2945 20.2762 10.3703 20.41 10.5047C20.5437 10.6391 20.6186 10.8211 20.6181 11.0106C20.6175 11.2002 20.5417 11.3817 20.4071 11.5154C20.2726 11.649 20.0904 11.7238 19.9007 11.7233L1.20803 11.6711C1.11408 11.671 1.02109 11.6522 0.934396 11.6161C0.847703 11.5799 0.769019 11.527 0.702862 11.4603Z"/>
            <path d="M10.0231 20.8237C9.95674 20.7572 9.9042 20.6783 9.86847 20.5915C9.83275 20.5047 9.81454 20.4117 9.8149 20.3178L9.8671 1.64319C9.86763 1.45366 9.94351 1.27209 10.078 1.13844C10.2126 1.0048 10.3947 0.93001 10.5844 0.930539C10.7742 0.931068 10.9559 1.00687 11.0897 1.14127C11.2235 1.27566 11.2983 1.45765 11.2978 1.64718L11.2456 20.3218C11.2452 20.4632 11.2029 20.6012 11.1239 20.7186C11.045 20.8359 10.933 20.9272 10.8022 20.9809C10.6713 21.0346 10.5274 21.0483 10.3888 21.0204C10.2501 20.9924 10.1228 20.9239 10.0231 20.8237Z"/>
            </g>
            <defs>
            <clipPath id="clip0_3205_17086">
            <rect width="14.6412" height="14.6412" fill="white" transform="matrix(0.705471 0.708738 -0.709419 0.704787 10.5861 0.634033)"/>
            </clipPath>
            </defs>
            </svg>
            
        );
    }
}

export default AddIcon;
