/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { lazy, Suspense } from 'react';

import Loader from 'Component/Loader/Loader.component';

import { MORE_INFORMATION } from '../../component/AmastyMoreInformation/AmastyMoreInformation.config';

export const MoreInformation = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-info" */
    '../../component/AmastyMoreInformation'
));

const amastyMoreInformationComponent = (key, instance) => {
    const {
        activeProduct: {
            name
        }
    } = instance.props;

    return (
        <Suspense fallback={ <Loader /> } key={ key }>
            <MoreInformation
              key={ key }
              productName={ name }
            />
        </Suspense>
    );
};

export const addAmastyMoreInformationTab = (member, instance) => ({
    ...member,
    [MORE_INFORMATION]: {
        name: __('More information'),
        shouldTabRender: () => true,
        render: (key) => amastyMoreInformationComponent(key, instance)
    }
});

export default {
    'Route/ProductPage/Component': {
        'member-property': {
            tabMap: addAmastyMoreInformationTab
        }
    }
};
