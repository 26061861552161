/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Link from 'Component/Link';

import {
    OptionsType, OrderItemSettingsType, OrderItemType, PlaceholderOptionType, ReturnOptionsType
} from '../../type/AmastyRMA.type';
import { getDetailsId, getQtyOptions } from '../../util/Utils';
import AmastyReturnOption from '../AmastyReturnOption';
import {
    OPTION_TYPE_QTY
} from '../AmastyReturnOption/AmastyReturnOption.config';
import { URL_PREFIX } from '../MyReturnsTab/MyReturnsTab.config';
import {
    INCREMENT_ID_LENGTH, NO_RETURNABLE_REASON_ALREADY_RETURNED,
    NO_RETURNABLE_REASON_EXPIRED_PERIOD,
    NO_RETURNABLE_REASON_ITEM_WAS_ON_SALE,
    NO_RETURNABLE_REASON_ITEM_WASNT_SHIPPED,
    NO_RETURNABLE_REASON_REFUNDED
} from './AmastyOrderItem.config';

/** @namespace Scandiweb/AmastyRma/Component/AmastyOrderItem/Component */
export class AmastyOrderItemComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        index: PropTypes.number.isRequired,
        id: PropTypes.string.isRequired,
        item: OrderItemType.isRequired,
        changeItemState: PropTypes.func.isRequired,
        onReturnOptionChange: PropTypes.func.isRequired,
        changeCheckboxState: PropTypes.func.isRequired,
        checkboxesTriggers: PropTypes.objectOf(PropTypes.bool).isRequired,
        options: PropTypes.objectOf(PropTypes.oneOfType([
            PlaceholderOptionType.isRequired,
            OptionsType.isRequired
        ])).isRequired,
        settings: OrderItemSettingsType.isRequired,
        returnOptions: ReturnOptionsType.isRequired
    };

    noReturnableRenderMap = {
        [NO_RETURNABLE_REASON_ITEM_WASNT_SHIPPED]: {
            render: () => this.renderItemWasntShippedBlock()
        },
        [NO_RETURNABLE_REASON_REFUNDED]: {
            render: () => this.renderGenericNoReturnableBlock({
                cause: __('This product is already refunded.')
            })
        },
        [NO_RETURNABLE_REASON_ALREADY_RETURNED]: {
            render: () => this.renderAlreadyReturnedBlock()
        },
        [NO_RETURNABLE_REASON_EXPIRED_PERIOD]: {
            render: () => this.renderGenericNoReturnableBlock({
                cause: __("The return for this product can't be processed."),
                warning: __('The return period expired.')
            })
        },
        [NO_RETURNABLE_REASON_ITEM_WAS_ON_SALE]: {
            render: () => this.renderGenericNoReturnableBlock({
                cause: __('This product cannot be returned.'),
                warning: __('This product was on sale.')
            })
        }
    };

    renderContent() {
        return (
            <div className="amrma-product-item">
                <div className="amrma-product">
                    { this.renderCheckbox() }
                    { this.renderProductInformation() }
                </div>
                { this.renderReturnOptionsBlock() }
            </div>
        );
    }

    renderCheckbox() {
        const {
            id,
            item: {
                amrma_order_item: {
                    is_returnable
                },
                product_name,
                product_image
            },
            changeItemState
        } = this.props;

        return (
            <div className="amrma-checkbox-container">
                <Field
                  type={ FIELD_TYPE.checkbox }
                  attr={ {
                      id,
                      className: 'amrma-checkbox amrma-return-item',
                      disabled: !is_returnable
                  } }
                  events={ {
                      onChange: changeItemState
                  } }
                />
                { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                <label
                  htmlFor={ id }
                  className="amrma-label-container"
                >
                    <span className="amrma-image">
                        <img src={ product_image } alt={ product_name } />
                    </span>
                </label>
            </div>
        );
    }

    renderProductInformation() {
        const {
            item,
            item: {
                amrma_order_item,
                product_name,
                product_sku
            }
        } = this.props;

        return (
            <div className="amrma-product-information">
                <strong className="amrma-item-name">{ product_name }</strong>
                <p className="_nomargin">
                    <strong>
                        { __('SKU') }
                        :
                        { ' ' }
                    </strong>
                    { product_sku }
                </p>
                { this.renderOrderOptionsBlock(item, amrma_order_item) }
            </div>
        );
    }

    renderOrderOptionsBlock({ selected_options }, { order_item_id }) {
        const {
            changeCheckboxState,
            checkboxesTriggers
        } = this.props;

        if (!selected_options?.length) {
            return null;
        }

        return (
            <div>
                <Field
                  type={ FIELD_TYPE.checkbox }
                  attr={ {
                      id: getDetailsId(order_item_id),
                      className: 'amrma-checkbox'
                  } }
                  label={ __('Details') }
                  events={ {
                      onChange: changeCheckboxState
                  } }
                />
                <div
                  className="amrma-moreinfo"
                  hidden={ !checkboxesTriggers[getDetailsId(order_item_id)] }
                >
                    { selected_options.map(this.renderProductOptionLabel) } 
                </div>
            </div>
        );
    }

    renderProductOptionLabel(option) {
        const { label, title, value = [] } = option; 
		let values = eval(  value  ); 

        if (Array.isArray(values) && values.length > 0) {
             
            if (label=="Options") {  
               let optionsValues = [];
				for (var i = 0; i < values.length; i++) {  
					optionsValues[i] = <li><span>{values[i].label}</span> : {values[i].value} </li>;
					//optionsValues[i] = <div><strong>{values[i].label}: </strong> {values[i].value} </div>;
				} 
				
				return <><ul className="CartItem-Options"> {optionsValues} </ul></>;
				//return <><p class="amrma-info"> {optionsValues} </p></>;
            } else {
				let optionsValues = <li><span>{ `${label} ` }</span> : {value} </li>;
				return <><ul className="CartItem-Options"> {optionsValues} </ul></>;
						
				/* return <><p className="amrma-info">
							<strong>
								{ `${label}: ` }
							</strong>
							{ value }
						</p></>; */
			} 
        }
		
        return '';
    }
     
    renderOrderOptionsElements = ({ label, value }) => (
		 
			<p className="amrma-info">
				<strong>
					{ `${label}: ` }
				</strong>
				{ value }
			</p>
		 
    );

    renderReturnOptionsBlock() {
        const {
            id: triggerId,
            item: {
                amrma_order_item: {
                    is_returnable,
                    order_item_id,
                    no_returnable_reason
                }
            },
            returnOptions,
            checkboxesTriggers
        } = this.props;

        if (!is_returnable) {
            const { render: renderNoReturnableBlock } = this.noReturnableRenderMap[no_returnable_reason];
            return renderNoReturnableBlock();
        }

        return (
            <div
              className="amrma-product-message amrma-return-settings-container"
              hidden={ !checkboxesTriggers[triggerId] }
            >
                <div className="amrma-message-container -options">
                    <div className="amrma-return-qty">
                        <label
                          htmlFor={ `amrma-qty-select-${order_item_id}` }
                          className="amrma-label"
                        >
                            { __('Return Qty') }
                        </label>
                        { this.renderQtySelectBlock() }
                    </div>
                    { returnOptions.map(this.renderReturnOption) }
                </div>
            </div>
        );
    }

    renderReturnOption = ({ index, optionType, options }) => {
        const {
            item: {
                product_name
            },
            options: {
                placeholder
            },
            onReturnOptionChange
        } = this.props;

        let optionTypeLabel = "";

        if(optionType=="reason") {
            optionTypeLabel = "Return Reason";
        } else if(optionType=="condition") {
            optionTypeLabel = "Condition";
        } else if(optionType=="resolution") {
            optionTypeLabel = "Resolution";
        }    

        return (
            <div className="amrma-return-select">
                <h4 className="amrma-title">{optionTypeLabel}</h4>
                <AmastyReturnOption
                  defaultValue={ placeholder.value }
                  productName={ product_name }
                  optionType={ optionType }
                  orderItemIndex={ index }
                  options={ options }
                  onChange={ onReturnOptionChange }
                />
            </div>
        );
    };

    renderItemWasntShippedBlock() {
        return (
            <div className="amrma-product-message">
                <div className="amrma-message-container -gray">
                    <p className="amrma-cause _nomargin">
                        { __("This product wasn't shipped.") }
                    </p>
                </div>
            </div>
        );
    }

    renderAlreadyReturnedBlock() {
        const {
            item: {
                amrma_order_item: {
                    already_returned_requests
                }
            }
        } = this.props;

        return (
            <div className="amrma-product-message">
                <div className="amrma-message-container -gray">
                    <p className="amrma-cause _nomargin">
                        { __('Rma request for this product is already created.') }
                        <br />
                        { __(' Existing Return(s):') }
                    </p>
                    <div className="amrma-returns-container">
                        { already_returned_requests.map(this.renderExistingReturnLink) }
                    </div>
                </div>
            </div>
        );
    }

    renderExistingReturnLink = (returnRequestId) => (
        <p className="_nomargin amrma-return-link">
            <Link
              key={ returnRequestId }
              to={ `/${URL_PREFIX}/account/view/request/${returnRequestId}` }
              target="_blank"
              rel="noreferrer"
            >
                { `#${returnRequestId.toString().padStart(INCREMENT_ID_LENGTH, '0')}` }
            </Link>
        </p>
    );

    renderGenericNoReturnableBlock(
        {
            cause,
            warning
        }
    ) {
        return (
            <div className="amrma-product-message">
                <div className="amrma-message-container -gray">
                    <p className="amrma-cause _nomargin">
                        { cause }
                    </p>
                    <p className="amrma-warning-message _nomargin">
                        { warning }
                    </p>
                    { __('If you have questions, please contact the store administrator: ') }
                    { this.renderAdministratorPhoneNumberBlock() }
                    { this.renderAdministratorEmailBlock() }
                </div>
            </div>
        );
    }

    renderAdministratorPhoneNumberBlock() {
        const {
            settings: {
                isShowAdministratorContact,
                administratorPhoneNumber
            }
        } = this.props;

        if (!isShowAdministratorContact || !administratorPhoneNumber) {
            return null;
        }

        return (
            <p className="amrma-phone _nomargin">
                { /* eslint-disable-next-line react/forbid-elements */ }
                <a href={ `tel:${administratorPhoneNumber}` }>{ administratorPhoneNumber }</a>
            </p>
        );
    }

    renderAdministratorEmailBlock() {
        const {
            settings: {
                isShowAdministratorContact,
                administratorEmail
            }
        } = this.props;

        if (!isShowAdministratorContact || !administratorEmail) {
            return null;
        }

        return (
            <p className="amrma-adminmail _nomargin">
                <Link to={ `mailto:${administratorEmail}` }>{ administratorEmail }</Link>
            </p>
        );
    }

    renderQtySelectBlock() {
        const {
            index: i,
            item: {
                amrma_order_item: {
                    purchased_qty,
                    available_qty
                }
            },
            onReturnOptionChange
        } = this.props;

        if (!Number.isInteger(purchased_qty)) {
            return (
                <input
                  className="amrma-item-qty"
                />
            );
        }

        return (
            <div className="amrma-qty-select">
                <AmastyReturnOption
                  defaultValue={ getQtyOptions(available_qty)[0].value }
                  optionType={ OPTION_TYPE_QTY }
                  orderItemIndex={ i }
                  options={ getQtyOptions(available_qty) }
                  onChange={ onReturnOptionChange }
                />
                <span className="amrma-total">
                    /
                    { ' ' }
                    { available_qty }
                </span>
            </div>
        );
    }

    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return null;
        }

        return (
            <div block="AmastyOrderItem">
                { this.renderContent() }
            </div>
        );
    }
}

export default AmastyOrderItemComponent;
