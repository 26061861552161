/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { OptionsType, StringOrNumberType } from '../../type/AmastyRMA.type';
import AmastyReturnOption from './AmastyReturnOption.component';

/** @namespace Scandiweb/AmastyRma/Component/AmastyReturnOption/Container */
export class AmastyReturnOptionContainer extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        optionType: PropTypes.string.isRequired,
        orderItemIndex: PropTypes.number.isRequired,
        defaultValue: StringOrNumberType.isRequired,
        options: OptionsType.isRequired
    };
    
    __construct(props) {
        super.__construct(props);

        this.state = {
            customReturnOptionsReset: [], 
        };
    }

    containerFunctions = {
        changeOptionState: this.changeOptionState.bind(this)
    };

    changeOptionState(value) {
        const {
            onChange,
            optionType,
            orderItemIndex,
            options
        } = this.props;
        
        const customReturnOptionsResetTmp1 = [onChange,
            optionType,
            orderItemIndex];
        
        this.setState({customReturnOptionsReset: customReturnOptionsResetTmp1});
        
        const {
            customReturnOptionsReset
        } = this.state;

        onChange(value, optionType, orderItemIndex);
    }

    containerProps() {
        const {
            defaultValue,
            options
        } = this.props;

        return {
            defaultValue,
            options
        };
    }

    render() {
        return (
            <AmastyReturnOption
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default AmastyReturnOptionContainer;
