/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { lazy } from 'react';

import MyReturnsTabComponent from '../../component/MyReturnsTab';
import { MY_RETURNS, URL_PREFIX } from '../../component/MyReturnsTab/MyReturnsTab.config';
import { addNewTabToTabsMap } from '../../util/MyAccount';

export const AmastyMyReturnsPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-address" */
    '../../route/AmastyMyReturnsPage'
));

const addAmastyMyReturnsToTabMap = (originalMembers) => {
    const newTab = {
        [MY_RETURNS]: {
            url: `/${ URL_PREFIX }/account/history`,
            tabName: __('My Returns'),
            isFullUrl: true
        }
    };

    return addNewTabToTabsMap(originalMembers, newTab);
};

const addAmastyMyReturnsToRenderMap = (originalMembers) => ({
    ...originalMembers,
    [MY_RETURNS]: MyReturnsTabComponent
});

export default {
  /*  'Route/MyAccount/Container': {
        'static-member': {
            tabMap: addAmastyMyReturnsToTabMap
        }
    },
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap: addAmastyMyReturnsToRenderMap
        }
    }*/
};
