/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './MinusIcon.style';

/** @namespace Component/MinusIcon/Component */
export class MinusIcon extends PureComponent {
    static propTypes = {
        isPrimary: PropTypes.bool
    };

    static defaultProps = {
        isPrimary: false
    };

    render() {
        const { isPrimary } = this.props;

        return (
            <svg
              block="MinusIcon"
              mods={ { isPrimary } }
              width="21"
              height="22"
              viewBox="0 0 21 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M0.751257 11.4603C0.651475 11.3601 0.583681 11.2326 0.556452 11.0939C0.529224 10.9552 0.543782 10.8115 0.598286 10.6811C0.65279 10.5507 0.744791 10.4393 0.862653 10.3611C0.980514 10.2829 1.11894 10.2414 1.26042 10.2418L19.9531 10.294C20.1428 10.2945 20.3246 10.3703 20.4584 10.5047C20.5921 10.6391 20.667 10.8211 20.6665 11.0106C20.6659 11.2002 20.5901 11.3817 20.4555 11.5154C20.321 11.649 20.1388 11.7238 19.9491 11.7233L1.25643 11.6711C1.16248 11.671 1.06948 11.6522 0.982792 11.6161C0.896099 11.5799 0.817414 11.527 0.751257 11.4603Z" />
            </svg>
        );
    }
}

export default MinusIcon;
