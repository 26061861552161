/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyRma/Query/AmastyReturnInformation/Query */
export class AmastyReturnInformationQuery {
    getProductsField(name) {
        return new Field('products')
            .addArgument('filter', 'ProductAttributeFilterInput', { name: { match: name } })
            .addField(this.getProductsItemsField());
    }

    getProductsItemsField() {
        return new Field('items')
            .addField(this.getAmRmaReturnInfoField());
    }

    getAmRmaReturnInfoField() {
        return new Field('amrma_return_info')
            .addFieldList(this.getAmRmaReturnInfoFields());
    }

    getAmRmaReturnInfoFields() {
        return [
            'code',
            'label',
            'value'
        ];
    }
}

export default new AmastyReturnInformationQuery();
