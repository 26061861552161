/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Link from 'Component/Link';
import Loader from 'Component/Loader';

import { URL_PREFIX } from '../../component/MyReturnsTab/MyReturnsTab.config';
import {
    AmRmaOrderListType,
    AmRmaReturnsForCurrentCustomerType, AmRmaSettingsMyReturnsType, OptionsType
} from '../../type/AmastyRMA.type';

import './AmastyMyReturnsPage.style';

/** @namespace Scandiweb/AmastyRma/Route/AmastyMyReturnsPage/Component */
export class AmastyMyReturnsPageComponent extends PureComponent {
    static defaultProps = {
        orderLink: ''
    };

    static propTypes = {
        getAmRmaOrderList: AmRmaOrderListType.isRequired,
        getAmRmaReturnsForCurrentCustomer: AmRmaReturnsForCurrentCustomerType.isRequired,
        getAmRmaSettings: AmRmaSettingsMyReturnsType.isRequired,
        isLoading: PropTypes.bool.isRequired,
        orderLink: PropTypes.string,
        setOrderLink: PropTypes.func.isRequired,
        ordersOptions: OptionsType.isRequired,
        getRequestQty: PropTypes.func.isRequired
    };

    renderContent() {
        return (
            <div>
                { this.renderOrderSelectBlock() }
                { this.renderReturnsBlock() }
            </div>
        );
    }

    renderNoOrdersMessage() {
        return (
            <div>
                <div className="message info empty">
                    { __('You have no returnable orders yet.') }
                </div>
            </div>
        );
    }

    renderOrderSelectBlock() {
        const {
            orderLink,
            setOrderLink,
            ordersOptions
        } = this.props;

        if (!ordersOptions?.length) {
            return this.renderNoOrdersMessage();
        }

        return (
            <div className="amreturns-order-select">
                <div className="amreturns-title-container">
                    <span className="amreturns-select-title">
                        { __('Create new return request') }
                    </span>
                </div>
                <div className="amreturns-orders-container">
                    <Field
                      type={ FIELD_TYPE.select }
                      attr={ {
                          id: 'amreturn-order',
                          name: 'amreturns-orders-select',
                          defaultValue: ordersOptions[0].value,
                          noPlaceholder: true
                      } }
                      options={ ordersOptions }
                      events={ {
                          onChange: setOrderLink
                      } }
                      className="amreturn-select"
                    />
                    <Link
                      to={ orderLink }
                      block="Button"
                      mods={ { capitalize: true, shadow: true } }
                      className="amreturn-btn"
                      title={ __('Return') }
                    >
                        { __('Return') }
                    </Link>
                </div>
            </div>
        );
    }

    renderNoReturnsMessage() {
        return (
            <div key={ 1 } className="message info empty">
                { __('You have placed no return requests.') }
            </div>
        );
    }

    renderReturnsBlock() {
        const {
            getAmRmaOrderList,
            getAmRmaReturnsForCurrentCustomer
        } = this.props;

        if (!getAmRmaOrderList?.length) {
            return null;
        }

        if (!getAmRmaReturnsForCurrentCustomer?.length) {
            return this.renderNoReturnsMessage();
        }

        return (
            <div className="amrma-table-container">
                <div className="Tablecommon">
                    <div className='Tablecommon-overflowscroll'>
                        <table id="my-returns-table">
                            <thead>
                                <tr>
                                    <th className="id">{ __('Items') }</th>                                    
                                    <th className="id">{ __('Id') }</th>
                                    <th className="date">{ __('Date') }</th>
                                    <th className="total">{ __('Order #') }</th>
                                    <th className="status">{ __('Return Status') }</th>
                                    <th className="blank">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                { getAmRmaReturnsForCurrentCustomer.map(this.renderReturnsTableBody) }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
	
	renderReturnsDate(created_at) {
		const cretaedDateArray = created_at.split(" ");
		return cretaedDateArray[0];
	}
	
	renderReturnsCssClass(rmastatus) {
		let rmastatusStr = rmastatus.split(" ").join("");
		rmastatusStr = rmastatusStr.toLowerCase();
		
		if( rmastatusStr == "new" ) {
			rmastatusStr = "New";
		} else if( rmastatusStr == "updatedbycustomer" ) {
			rmastatusStr = "Processing";
		} else if( rmastatusStr == "approved" ) {
			rmastatusStr = "Approved";
		} else if( rmastatusStr == "complete" ) {
			rmastatusStr = "Completed";
		} else if( rmastatusStr == "resolvedandrated" ) {
			rmastatusStr = "Completed";
		} else if( rmastatusStr == "" ) {
			rmastatusStr = "";
		} 
		
		return "amrma-status -pending Status-" + rmastatusStr;
	}

    renderReturnsTableBody = ({
        request_id,
        new_message,
        small_image,
        created_at,
        order_increment_id,
        request_items,
        status: {
            color: statusColor,
            label: statusLabel
        }
    }) => {
        const {
            getAmRmaSettings: {
                isChatEnabled
            },
            getRequestQty
        } = this.props;

        return (
            <tr key={ request_id } className="amrma-row">
                <td data-th={ __('Item') } className="col item">
                    <div
                      className={ `amrma-image${(isChatEnabled && !new_message) ? ' new-message' : ''} ` }
                    >
                        <img src={ small_image } alt="product" />
                    </div>
                </td>                
                <td data-th={ __('Id') } className="col id">{ request_id }</td>
                <td data-th={ __('Date') } className="col date">{ this.renderReturnsDate(created_at) }</td>
                <td data-th={ __('Order #') } className="col order">{ order_increment_id }</td>
                <td data-th={ __('Status') } className="col status">
                    <span className={ this.renderReturnsCssClass(statusLabel) } >
                        { statusLabel }
                    </span>
                </td>
                <td className="col actions">
                    <Link
                      to={ `/${URL_PREFIX}/account/view/request/${request_id}` }
                      className="MyAccount-link"
                      title={ __('View Return') }
                    >
                        { __('View Return') }
                    </Link>
                </td>
            </tr>
        );
    };

    render() {
        const {
            isLoading
        } = this.props;

        if (isLoading) {
            return (
                <Loader isLoading />
            );
        }

        return (
            <div block="AmastyMyReturnsPage">
                { this.renderContent() }
            </div>
        );
    }
}

export default AmastyMyReturnsPageComponent;
