/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { fetchQuery } from 'Util/Request';

import AmastyReturnInformationQuery from '../../query/AmastyReturnInformation.query';
import AmastyMoreInformation from './AmastyMoreInformation.component';

/** @namespace Scandiweb/AmastyRma/Component/AmastyMoreInformation/Container */
export class AmastyMoreInformationContainer extends PureComponent {
    static propTypes = {
        productName: PropTypes.string.isRequired
    };

    containerFunctions = {
    };

    componentDidMount() {
        this.fetchReturnInformation();
    }

    __construct(props) {
        super.__construct(props);
        this.state = {
            isLoading: true,
            returnInfo: []
        };
    }

    async fetchReturnInformation() {
        const { productName } = this.props;
        const {
            products: {
                items: [
                    { amrma_return_info } = {}
                ]
            }
        } = await fetchQuery(
            AmastyReturnInformationQuery.getProductsField(productName)
        );

        this.setState({
            returnInfo: amrma_return_info,
            isLoading: false
        });
    }

    containerProps() {
        const {
            isLoading,
            returnInfo
        } = this.state;

        return {
            isLoading,
            returnInfo
        };
    }

    render() {
        return (
            <AmastyMoreInformation
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default AmastyMoreInformationContainer;
