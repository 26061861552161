/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { fetchMutation, fetchQuery } from 'Util/Request';

import { URL_PREFIX } from '../../component/MyReturnsTab/MyReturnsTab.config';
import AmastyChatMutation from '../../query/AmastyChat.mutation';
import AmastyNewReturnMutation from '../../query/AmastyNewReturn.mutation';
import AmastyNewReturnQuery from '../../query/AmastyNewReturn.query';
import { sendFiles } from '../../util/FileUploader';
import { getArrayFromRefs, getRemainingFiles, removeEmptySlots } from '../../util/Utils';
import AmastyNewReturnComponent from './AmastyNewReturn.component';
import {
    ATTRIBUTE_FILE_HASH,
    ATTRIBUTE_KEY,
    ID_POPUP_INFO,
    LOGIN_URL,
    PLACEHOLDER_VALUE
} from './AmastyNewReturn.config';

/** @namespace Scandiweb/AmastyRma/Route/AmastyNewReturn/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Scandiweb/AmastyRma/Route/AmastyNewReturn/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showMyPopUp: (popupKey, payload) => dispatch(showPopup(popupKey, payload)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Scandiweb/AmastyRma/Route/AmastyNewReturn/Container */
export class AmastyNewReturnContainer extends PureComponent {
    files = [];

    static propTypes = {
        showMyPopUp: PropTypes.func.isRequired
    };

    containerFunctions = {
        uploadFiles: this.uploadFiles.bind(this),
        deleteUploadedFile: this.deleteUploadedFile.bind(this),
        submitNewRequest: this.submitNewRequest.bind(this),
        getOrderItemRef: this.getOrderItemRef.bind(this),
        getCustomFieldRef: this.getCustomFieldRef.bind(this),
        changePolicyState: this.changePolicyState.bind(this),
        changeOrderItemState: this.changeOrderItemState.bind(this),
        changeReturnOptionState: this.changeReturnOptionState.bind(this)
    };

    componentDidMount() {
        if (!isSignedIn()) {
            history.push(LOGIN_URL);
        }

        this.loadState();
    }

    __construct(props) {
        super.__construct(props);
        this.routeParams = props.match.params;

        this.state = {
            isLoading: true,
            uploadedFiles: [],
            checkboxesTriggers: {},
            customFieldsRefs: {},
            getAmRmaSettings: {},
            customer: {},
            orderItems: [],
            placeholderOption: {},
            reasonsOptions: [],
            reasonsOptionsMain: [],
            conditionsOptions: [],
            resolutionsOptions: [],
            newReturnRequest: {}
        };
    }

    async loadState() {
        const {
            newReturnQuery: {
                getAmRmaSettings,
                customer,
                getAmRmaSettings: {
                    allReasons,
                    allConditions,
                    allResolutions
                },
                customer: {
                    orders: {
                        items: [
                            {
                                increment_id
                            }
                        ]
                    }
                }
            },
            orderItems
        } = await this.fetchNewReturn(this.routeParams.orderIncrementId);

        const placeholderOption = {
            value: PLACEHOLDER_VALUE,
            label: __('Please Choose...'),
            isPlaceholder: true
        };

        this.setState({
            getAmRmaSettings,
            customer,
            orderItems,
            placeholderOption,
            reasonsOptions: [
                placeholderOption,
                ...allReasons.map(({ reason_id, label }) => ({ value: reason_id, label }))
            ],
            reasonsOptionsMain: [
                placeholderOption,
                ...allReasons.map(({ reason_id, label }) => ({ value: reason_id, label }))
            ],
            conditionsOptions: [
                placeholderOption,
                ...allConditions.map(({ condition_id, label }) => ({ value: condition_id, label }))
            ],
            resolutionsOptions: [
                placeholderOption,
                ...allResolutions.map(({ resolution_id, label }) => ({ value: resolution_id, label }))
            ],
            commentElement: createRef(),
            newReturnRequest: {
                order_id: increment_id,
                return_items: [],
                policy_allowed: 0,
                custom_fields: []
            },
            isLoading: false
        });
    }

    async fetchNewReturn(orderIncrementId) {
        const newReturnQuery = await fetchQuery([
            AmastyNewReturnQuery.getSettingsField(),
            AmastyNewReturnQuery.getOrderByIdField(orderIncrementId)
        ]);

        const {
            customer: {
                orders: {
                    items: [
                        { items: orderItems }
                    ]
                }
            }
        } = newReturnQuery;

        const skuSet = new Set(orderItems.map(({ product_sku }) => product_sku));

        const {
            products: {
                items: products
            }
        } = await fetchQuery(AmastyNewReturnQuery.getProductsField(skuSet));

        if (products.length > 0) {
            return {
                newReturnQuery,
                orderItems: this.addImageToOrderItems({ orderItems, products })
            };
        }

        return {
            newReturnQuery,
            orderItems
        };
    }

    addImageToOrderItems({ orderItems, products }) {
        return orderItems.map((orderItem) => {
            const { product_sku } = orderItem;
            const newOrderItem = orderItem;
            newOrderItem.product_image = this.getProductImage({ products, product_sku });

            return newOrderItem;
        });
    }

    getProductImage({ products, product_sku }) {
        return products.reduce((
            acc,
            {
                sku,
                small_image: {
                    url: imageUrl = ''
                } = {}
            }
        ) => {
            if (sku === product_sku) {
                return imageUrl;
            }

            return acc;
        }, '');
    }

    changeOrderItemState({
        e,
        index,
        order_item_id,
        product_name
    }) {
        const { newReturnRequest } = this.state;

        if (e.target.checked) {
            newReturnRequest.return_items[index] = {
                order_item_id,
                return: 1,
                qty: 1,
                reason: PLACEHOLDER_VALUE,
                condition: PLACEHOLDER_VALUE,
                resolution: PLACEHOLDER_VALUE,
                product_name
            };
        } else {
            newReturnRequest.return_items.splice(index, 1);
        }
        this.setState({ newReturnRequest });
    }

    changeReturnOptionState(value, type, i) {
		 
        const { newReturnRequest , reasonsOptions, reasonsOptionsMain, orderItems } = this.state;
        
        if(type=="reason") {	
			let iValue =  -1;
			if (typeof reasonsOptions[1]['i'] !== 'undefined') {
				iValue = reasonsOptions[1]['i'];
			} else {
				iValue = i;
			}
			//alert(iValue);
			
			if(iValue==i) {
							
				let reasonsOptionsTempObj = {};
				reasonsOptionsMain.map(function(reasonsOptionMain){
					if(value==reasonsOptionMain.value){
						reasonsOptionsTempObj.label = reasonsOptionMain.label;
						reasonsOptionsTempObj.value = value;
						reasonsOptionsTempObj.i = i;
					}
				});
				
				console.log("reasonsOptions iiiiiiii aa", reasonsOptions  );    
				console.log("reasonsOptions iiiiiiii", reasonsOptions[0]['i'] );    
				
				
			   //const reasonsOptionsTemp = [{"label":"Damaged During Shipping", "value":"7"}];
			   const reasonsOptionsSelectTempObj = {"label":"Please Choose...", "value":"-1"};
			   //const reasonsOptionsTemp = [reasonsOptionsTempObj];
			   const reasonsOptionsTemp = [reasonsOptionsSelectTempObj, reasonsOptionsTempObj];
			   
			   ///
				console.log("reasonsOptions newReturnRequest return_items single item a", orderItems );   
				console.log("reasonsOptionsnewReturnRequest ", orderItems , " reasonsOptions", reasonsOptions, " reasonsOptionsTemp", reasonsOptionsTemp );   
				let orderItemsCnt = 0;
				let orderItemsMainOrder_item_id = -1;
				if (typeof reasonsOptions[1]['i'] !== 'undefined') {
					orderItems.map(function(orderItem){
						if(orderItemsCnt==reasonsOptions[1]['i']) { // need to changes this
							orderItemsMainOrder_item_id = orderItem.amrma_order_item.order_item_id;
						}
						orderItemsCnt++;
					});    
				}
				
				//alert(orderItemsMainOrder_item_id + " == " + newReturnRequest.return_items.length);
				
			  /* newReturnRequest.return_items.map(function(return_item){
					 console.log("reasonsOptions newReturnRequest return_items single a ", return_item ); 
						return_item.label = 'Damaged During Shipping';
						return_item.reason = value;
				});
				*/
				let itemCnter = 0;
				if(orderItemsMainOrder_item_id >= 0) {
					orderItems.map(function(orderItem){
						console.log("orderItems reasonsOptions", reasonsOptions[0]['i'], " itemCnter",  (itemCnter+1) ); 
						 
							console.log("orderItems reasonsOptions iff", reasonsOptions[0]['i'], " itemCnter",  (itemCnter+1) ); 
							 
							newReturnRequest.return_items.map(function(return_item){	
								console.log("orderItems reasonsOptions iff loop", return_item.order_item_id, " itemCnter",  orderItem.amrma_order_item.order_item_id , " orderItemsMainOrder_item_id", orderItemsMainOrder_item_id ); 
								//if(return_item.order_item_id != '213612') {
								if(return_item.order_item_id != orderItemsMainOrder_item_id) {
									if(return_item.order_item_id==orderItem.amrma_order_item.order_item_id) {		   
										//console.log("reasonsOptions newReturnRequest return_items single a order_item_id exist", return_item ); 
										let checkBoxItem = "amrma-checkbox-" + itemCnter;			
										//alert(checkBoxItem);			
										let el = document.getElementById(checkBoxItem);
										el.click(); 
										
									}
								}				 
							});
						//}
				 
						itemCnter++;
					});
				}
			   ///


				console.log("reasonsOptions", reasonsOptions ,"reasonsOptionsMain ", reasonsOptionsMain , "reasonsOptionsTemp" ,reasonsOptionsTemp ); 
				console.log("reasonsOptions value", value , "type" ,type,' i',i ); 
				console.log("reasonsOptions newReturnRequest return_items vv", newReturnRequest.return_items ); 
				//alert("newReturnRequest.return_items" + newReturnRequest.return_items.length + " i "+i);
				
				if (typeof newReturnRequest.return_items[i] !== 'undefined') {	
					newReturnRequest.return_items[i][type] = parseInt(value, 10);
				}
				
				this.setState({ newReturnRequest,reasonsOptions: reasonsOptionsTemp });
			} else {				
				newReturnRequest.return_items[i][type] = parseInt(value, 10);
				this.setState({ newReturnRequest });
			}
			
        } else {
			newReturnRequest.return_items[i][type] = parseInt(value, 10);
			this.setState({ newReturnRequest });
		}
        // this.loadState();
      // this.setState({ newReturnRequest });
    }

    async uploadFiles(e) {
        const {
            showMyPopUp
        } = this.props;

        const {
            uploadedFiles
        } = this.state;

        e.preventDefault();

        this.setState({
            isSubmitButtonDisabled: true
        });

        try {
            this.setState({
                uploadedFiles: uploadedFiles.concat(await sendFiles(Array.from(e.target.files)))
            });
        } catch (error) {
            this.setState({
                popupMessage: __('Error uploading file. File might be too large. Please try again!')
            });

            showMyPopUp(ID_POPUP_INFO);
        }

        this.setState({
            isSubmitButtonDisabled: false
        });
    }

    async deleteUploadedFile(e) {
        if (e.type === 'keyup') {
            if (e.code !== 'Enter' || e.code !== 'Space') {
                return;
            }
        }

        const {
            showMyPopUp
        } = this.props;

        this.setState({
            isDeleteButtonDisabled: true,
            isSubmitButtonDisabled: true
        });

        const elementFileHash = e.target.getAttribute(ATTRIBUTE_FILE_HASH);
        const result = await fetchMutation(AmastyChatMutation.deleteNewMessageFile(elementFileHash));

        if (result.error) {
            this.setState({
                popupMessage: __('An error occurred, please try again')
            });

            showMyPopUp(ID_POPUP_INFO);
        } else {
            const {
                uploadedFiles
            } = this.state;

            this.setState({
                uploadedFiles: getRemainingFiles({ files: uploadedFiles, filehash: elementFileHash })
            });
        }

        this.setState({
            isDeleteButtonDisabled: false,
            isSubmitButtonDisabled: false
        });
    }

    changePolicyState(e) {
        const { newReturnRequest } = this.state;
        if (e.target.checked) {
            newReturnRequest.policy_allowed = 1;
        } else {
            newReturnRequest.policy_allowed = 0;
        }
        this.setState({ newReturnRequest });
    }

    async submitNewRequest() {
        const {
            showMyPopUp
        } = this.props;

        const {
            newReturnRequest,
            newReturnRequest: {
                return_items,
                policy_allowed
            },
            getAmRmaSettings: {
                isReturnPolicyEnabled
            }
        } = this.state;

        const selectedReturnItems = removeEmptySlots(return_items);
        const errorMessages = [];

        if (selectedReturnItems.length === 0) {
            errorMessages.push(__('Items were not selected.'));
        }

        if (
            isReturnPolicyEnabled
            && policy_allowed !== 1
        ) {
            errorMessages.push(__('Please accept the Return Policy.'));
        }

        errorMessages.push(
            selectedReturnItems.reduce(this.getItemErrorMessages, '').trim()
        );

        const errorMessagesString = errorMessages.join('\n').trim();

        if (errorMessagesString !== '') {
            this.setState({
                popupMessage: errorMessagesString
            });
            showMyPopUp(ID_POPUP_INFO);
            return;
        }

        this.setState({
            isSubmitButtonDisabled: true
        });

        const {
            uploadedFiles,
            commentElement,
            customFieldsRefs
        } = this.state;

        newReturnRequest.return_items = selectedReturnItems.map(this.removeProductName);
        newReturnRequest.comment = commentElement.current.value;
        newReturnRequest.custom_fields = getArrayFromRefs(customFieldsRefs);
        newReturnRequest.files = uploadedFiles;

        try {
            const {
                placeAmRmaCustomerReturnRequest: {
                    request_id
                }
            } = await fetchMutation(
                AmastyNewReturnMutation.saveNewReturn(newReturnRequest)
            );

            history.push(`/${ URL_PREFIX }/account/view/request/${request_id}`);
        } catch (error) {
            this.setState({
                popupMessage: __('An error occurred. Please try again.')
            });
            showMyPopUp(ID_POPUP_INFO);
        }
    }

    removeProductName(item) {
        const newItem = item;
        /* eslint-disable fp/no-delete */
        delete newItem.product_name;
        return newItem;
    }

    getItemErrorMessages = (itemAcc, item) => (
        itemAcc + ['reason', 'condition', 'resolution'].reduce(
            (optionAcc, prop) => (
                item[prop] === PLACEHOLDER_VALUE
                    ? optionAcc + __('\nPlease select %s for %s', prop, item.product_name)
                    : optionAcc
            ), ''
        )
    );

    mapCustomFields = (customFieldRef) => ({
        key: customFieldRef.current.getAttribute(ATTRIBUTE_KEY),
        value: customFieldRef.current.value
    });

    getOrderItemRef(i) {
        const { orderItemsRefs = [] } = this.state;
        if (orderItemsRefs[i]) {
            return orderItemsRefs[i];
        }
        const newRef = createRef();
        orderItemsRefs[i] = newRef;
        this.setState({
            orderItemsRefs
        });

        return newRef;
    }

    getCustomFieldRef(code) {
        const { customFieldsRefs } = this.state;
        if (customFieldsRefs[code]) {
            return customFieldsRefs[code];
        }
        const newRef = createRef();
        customFieldsRefs[code] = newRef;
        this.setState({
            customFieldsRefs
        });

        return newRef;
    }

    containerProps() {
        const {
            isLoading,
            getAmRmaSettings,
            customer,
            orderItems,
            uploadedFiles,
            isSubmitButtonDisabled,
            isDeleteButtonDisabled,
            popupMessage,
            checkboxesTriggers,
            placeholderOption,
            reasonsOptions,
            conditionsOptions,
            resolutionsOptions,
            contactAdministratorMessage,
            commentElement
        } = this.state;

        return {
            isLoading,
            getAmRmaSettings,
            customer,
            orderItems,
            uploadedFiles,
            isSubmitButtonDisabled,
            isDeleteButtonDisabled,
            popupMessage,
            checkboxesTriggers,
            placeholderOption,
            reasonsOptions,
            conditionsOptions,
            resolutionsOptions,
            contactAdministratorMessage,
            commentElement
        };
    }

    render() {
        return (
            <AmastyNewReturnComponent
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AmastyNewReturnContainer);
