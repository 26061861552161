/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

export const StringOrNumberType = PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
]);

export const AmRmaOrderType = PropTypes.shape({
    label: PropTypes.string.isRequired,
    order_id: PropTypes.string.isRequired
});

export const AmRmaOrderListType = PropTypes.arrayOf(AmRmaOrderType);

export const AmRmaRequestItemType = PropTypes.shape({
    product_url_key: PropTypes.string.isRequired,
    qty: PropTypes.number.isRequired
});

export const AmRmaReturnStatusType = PropTypes.shape({
    color: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
});

export const AmRmaReturnType = PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    order_increment_id: PropTypes.string.isRequired,
    request_id: PropTypes.number.isRequired,
    request_items: PropTypes.arrayOf(AmRmaRequestItemType),
    status: AmRmaReturnStatusType
});

export const AmRmaReturnsForCurrentCustomerType = PropTypes.arrayOf(AmRmaReturnType);

export const AmRmaSettingsMyReturnsType = PropTypes.shape({
    isChatEnabled: PropTypes.bool.isRequired
});

export const PlaceholderOptionType = PropTypes.shape({
    label: StringOrNumberType.isRequired,
    value: StringOrNumberType.isRequired,
    isPlaceholder: PropTypes.bool.isRequired
});

export const OptionType = PropTypes.shape({
    label: StringOrNumberType.isRequired,
    value: StringOrNumberType.isRequired
});

export const OptionsType = PropTypes.arrayOf(OptionType);

export const AmRmaConditionType = PropTypes.shape({
    condition_id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    payer: PropTypes.string
});

export const AmRmaReasonType = PropTypes.shape({
    reason_id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    payer: PropTypes.number.isRequired
});

export const AmRmaResolutionType = PropTypes.shape({
    resolution_id: PropTypes.number.isRequired,
    status: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired
});

export const AmRmaCustomFieldsType = PropTypes.shape({
    code: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
});

export const AmRmaSettingsNewReturnType = PropTypes.shape({
    isShowAdministratorContact: PropTypes.bool.isRequired,
    administratorEmail: PropTypes.string.isRequired,
    administratorPhoneNumber: PropTypes.string.isRequired,
    policyUrl: PropTypes.string.isRequired,
    isReturnPolicyEnabled: PropTypes.bool.isRequired,
    customFieldsBlockTitle: PropTypes.string.isRequired,
    allConditions: PropTypes.arrayOf(AmRmaConditionType),
    allReasons: PropTypes.arrayOf(AmRmaReasonType),
    allResolutions: PropTypes.arrayOf(AmRmaResolutionType),
    customFields: PropTypes.arrayOf(AmRmaCustomFieldsType)
});

export const OrderBillingAddressType = PropTypes.shape({
    city: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    telephone: PropTypes.string.isRequired,
    street: PropTypes.arrayOf(PropTypes.string).isRequired
});

export const OrderItemType = PropTypes.shape({
    product_url_key: PropTypes.string.isRequired,
    product_sku: PropTypes.string.isRequired
});

export const OrderItemsType = PropTypes.arrayOf(OrderItemType);

export const OrderType = PropTypes.shape({
    increment_id: PropTypes.string.isRequired,
    billing_address: OrderBillingAddressType,
    items: OrderItemsType
});

export const OrdersType = PropTypes.shape({
    items: PropTypes.arrayOf(OrderType)
});

export const CustomerType = PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    orders: OrdersType
}).isRequired;

export const FileType = PropTypes.shape({
    filehash: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired
});

export const UploadedFilesType = PropTypes.arrayOf(FileType);

export const AmRmaRequestMessageType = PropTypes.shape({
    created: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    is_manager: PropTypes.bool.isRequired,
    is_system: PropTypes.bool.isRequired,
    message_id: PropTypes.number.isRequired,
    files: PropTypes.arrayOf(FileType)
});

export const AmRmaRequestMessagesType = PropTypes.arrayOf(AmRmaRequestMessageType);

export const AmRmaReturnByIdStatusType = PropTypes.shape({
    color: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    state: PropTypes.number.isRequired,
    status_id: PropTypes.number.isRequired
});

export const AmRmaReturnByIdRequestItemType = PropTypes.shape({
    request_item_id: PropTypes.number.isRequired,
    request_id: PropTypes.number.isRequired,
    order_item_id: PropTypes.number.isRequired,
    qty: PropTypes.number.isRequired,
    request_qty: PropTypes.number.isRequired,
    reason_id: PropTypes.number.isRequired,
    condition_id: PropTypes.number.isRequired,
    resolution_id: PropTypes.number.isRequired,
    product_name: PropTypes.string.isRequired,
    product_url_key: PropTypes.string.isRequired,
    item_status: PropTypes.string.isRequired
});

export const AmRmaReturnByIdType = PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    custom_fields: PropTypes.string.isRequired,
    customer_id: PropTypes.number.isRequired,
    customer_name: PropTypes.string.isRequired,
    manager_id: PropTypes.number.isRequired,
    modified_at: PropTypes.string.isRequired,
    order_id: PropTypes.number.isRequired,
    request_id: PropTypes.number.isRequired,
    rating: PropTypes.number.isRequired,
    order_increment_id: PropTypes.string.isRequired,
    rating_comment: PropTypes.string.isRequired,
    url_hash: PropTypes.string,
    status: AmRmaReturnByIdStatusType,
    request_items: PropTypes.arrayOf(AmRmaReturnByIdRequestItemType)
});

export const AmRmaSettingsViewReturnType = PropTypes.shape({
    isAskForFeedback: PropTypes.bool.isRequired,
    customFieldsBlockTitle: PropTypes.string.isRequired,
    allConditions: PropTypes.arrayOf(AmRmaConditionType),
    allReasons: PropTypes.arrayOf(AmRmaReasonType),
    allResolutions: PropTypes.arrayOf(AmRmaResolutionType),
    customFields: PropTypes.arrayOf(AmRmaCustomFieldsType)
});

export const ProductType = PropTypes.shape({
    sku: PropTypes.string.isRequired,
    small_image: PropTypes.shape({
        url: PropTypes.string.isRequired
    })
});

export const ProductsType = PropTypes.arrayOf(ProductType);

export const RefType = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
]);

export const OrderItemSettingsType = PropTypes.shape({
    isShowAdministratorContact: PropTypes.bool.isRequired,
    administratorEmail: PropTypes.string.isRequired,
    administratorPhoneNumber: PropTypes.string.isRequired
});

export const ReturnOptionType = PropTypes.shape({
    index: PropTypes.number.isRequired,
    optionType: PropTypes.string.isRequired,
    options: PropTypes.objectOf(PropTypes.string).isRequired
});

export const ReturnOptionsType = PropTypes.arrayOf(ReturnOptionType);
