/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';

import './CloseIcon.style';

/** @namespace Component/CloseIcon/Component */
export class CloseIcon extends PureComponent {
    render() {
        return (
            <svg
              block="CloseIcon"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M0.920032 12.0011C0.804128 12.0011 0.690822 11.9667 0.594445 11.9023C0.498068 11.838 0.42295 11.7464 0.378593 11.6394C0.334235 11.5323 0.322632 11.4145 0.345249 11.3008C0.367867 11.1871 0.42369 11.0827 0.505657 11.0007L11.3338 0.172616C11.4437 0.0627172 11.5927 0.000976562 11.7482 0.000976562C11.9036 0.000976563 12.0526 0.0627172 12.1625 0.172616C12.2724 0.282515 12.3342 0.431571 12.3342 0.586991C12.3342 0.742412 12.2724 0.891467 12.1625 1.00137L1.33441 11.8295C1.28004 11.884 1.21545 11.9272 1.14434 11.9566C1.07322 11.9861 0.996997 12.0012 0.920032 12.0011Z" />
<path d="M11.7481 12.0011C11.6712 12.0012 11.5949 11.9861 11.5238 11.9566C11.4527 11.9272 11.3881 11.884 11.3337 11.8295L0.505624 1.00137C0.395725 0.891467 0.333984 0.742412 0.333984 0.586991C0.333984 0.431571 0.395725 0.282515 0.505624 0.172616C0.615523 0.0627172 0.764578 0.000976563 0.919999 0.000976562C1.07542 0.000976563 1.22447 0.0627172 1.33437 0.172616L12.1625 11.0007C12.2445 11.0827 12.3003 11.1871 12.3229 11.3008C12.3455 11.4145 12.3339 11.5323 12.2896 11.6394C12.2452 11.7464 12.1701 11.838 12.0737 11.9023C11.9773 11.9667 11.864 12.0011 11.7481 12.0011Z" />
            </svg>
        );
    }
}

export default CloseIcon;
