/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Form from 'Component/Form';
import Link from 'Component/Link';
import Popup from 'Component/Popup';

import { AmRmaRequestMessagesType, RefType, UploadedFilesType } from '../../type/AmastyRMA.type';
import { ID_POPUP_CONFIRM } from './AmastyChat.config';

/** @namespace Scandiweb/AmastyRma/Component/AmastyChat/Component */
export class AmastyChatComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        messages: AmRmaRequestMessagesType.isRequired,
        newMessageFiles: UploadedFilesType.isRequired,
        confirmMessageDeletion: PropTypes.func.isRequired,
        uploadFiles: PropTypes.func.isRequired,
        submitMessage: PropTypes.func.isRequired,
        deleteUploadedFile: PropTypes.func.isRequired,
        updateNewMessageText: PropTypes.func.isRequired,
        isSubmitButtonDisabled: PropTypes.bool.isRequired,
        isDeleteButtonDisabled: PropTypes.bool.isRequired,
        chatBodyElement: RefType.isRequired,
        newMessageInput: RefType.isRequired,
        popupMessage: PropTypes.string.isRequired,
        closePopUp: PropTypes.func.isRequired,
        deleteMessage: PropTypes.func.isRequired
    };

    renderContent() {
        const {
            messages,
            isSubmitButtonDisabled,
            uploadFiles,
            newMessageFiles,
            submitMessage,
            updateNewMessageText,
            chatBodyElement,
            newMessageInput
        } = this.props;

        return (
            <div>
                <h2 className='amrma-title'>Chat</h2>
                <div className="amrma-chat-container">
                    <div ref={ chatBodyElement } className="amrma-chat-block">
                        { messages.map(this.renderMessageBlock) }
                    </div>
                    <Form
                      className="amrma-chat-control"
                      onSubmit={ submitMessage }
                    >
                        <div className="amrma-chat-send">
                            <div className="amrma-attach-file">
                                <label htmlFor="amrma-attach" className="amrma-label">
                                    <input
                                      type="file"
                                      id="amrma-attach"
                                      name="amrma-attach"
                                      multiple
                                      className="amrma-attach"
                                      accept=".pdf,.png,.jpg,.gif"
                                      onChange={ uploadFiles }
                                    />
                                </label>
                            </div>
                            <textarea
                              ref={ newMessageInput }
                              className="amrma-textarea"
                              rows="1"
                              placeholder="Message"
                              onChange={ updateNewMessageText }
                            />
                            <p className="amrma-hiddentext" />
                            <button
                              block="Button"
                              className="amrma-send"
                              disabled={ isSubmitButtonDisabled }
                            >
                                Send
                            </button>
                        </div>
                    </Form>
                    { newMessageFiles.map(this.renderNewMessageFilesBlock) }
                    { this.renderConfirmPopup() }
                </div>
            </div>
        );
    }

    renderMessageBlock = (
        {
            message_id,
            is_manager,
            is_system,
            username,
            message: userMessage,
            created,
            files: messageFiles
        }
    ) => (
        <div
          key={ message_id }
          id={ `message-${message_id}` }
          className={ `amrma-chat-message ${(is_manager || is_system) ? '-left' : '-right'}` }
        >
            <p className="amrma-username">{ username }</p>
            <div className="amrma-message-block">
                { this.renderDeleteButton({ is_manager, is_system, message_id }) }
                <p className="amrma-message">{ userMessage }</p>
                { messageFiles.map(this.renderMessageFileBlock) }
                <p className="amrma-date">{ created }</p>
            </div>
        </div>
    );

    renderMessageFileBlock = ({ filepath, filename }) => (
        <div className="amrma-chat-file">
            <Link
              className="amrma-file"
              isOpenInNewTab
              to={ filepath }
              rel="noreferrer"
            >
                { filename }
            </Link>
        </div>
    );

    renderNewMessageFilesBlock = ({ filehash, filename }) => {
        const {
            isDeleteButtonDisabled,
            deleteUploadedFile
        } = this.props;

        return (
            <div key={ filehash } id={ filehash } className="amrma-chat-file -preview">
                <span className="amrma-file">{ filename }</span>
                <span
                  className="amrma-file amrma-delete"
                  onClick={ deleteUploadedFile }
                  onKeyUp={ deleteUploadedFile }
                  role="button"
                  aria-label="Delete file"
                  disabled={ isDeleteButtonDisabled }
                  data-file-hash={ filehash }
                />
            </div>
        );
    };

    renderDeleteButton({ is_manager, is_system, message_id }) {
        const {
            confirmMessageDeletion
        } = this.props;

        if (is_manager || is_system) {
            return null;
        }

        return (
            <div className="amrma-delete-container">
                <svg onClick={ confirmMessageDeletion } onKeyUp={ confirmMessageDeletion } data-message-id={ message_id } aria-label="Delete message" tabIndex={ 0 } className="amrma-delete svg-close" aria-hidden="true" role="button" width="15" height="15">
                    <use href="#svg-close" xmlns="http://www.w3.org/1999/xlink"></use>
                </svg>
            </div>
        );
    }

    renderConfirmPopup() {
        const {
            deleteMessage,
            closePopUp,
            popupMessage
        } = this.props;

        return (
            <Popup
              id={ ID_POPUP_CONFIRM }
            >
                    <p style={ { whiteSpace: 'pre-wrap' } }>
                        { popupMessage }
                    </p>
                    <button onClick={ closePopUp } block="Button">Cancel</button>
                    <button
                      id="amrma-delete-message-btn"
                      block="Button"
                      onClick={ deleteMessage }
                      style={ { marginLeft: '10px' } }
                    >
                        { __('Delete') }
                    </button>
            </Popup>
        );
    }

    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return null;
        }

        return (
            <div className='AmastyChat'>
                { this.renderContent() }
            </div>
        );
    }
}

export default AmastyChatComponent;
