/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { CustomerType } from '../../type/AmastyRMA.type';

/** @namespace Scandiweb/AmastyRma/Component/AmastyCustomerInfo/Component */
export class AmastyCustomerInfoComponent extends PureComponent {
    static defaultProps = {
        returnCreatedAt: '',
        orderIncrementId: ''
    };

    static propTypes = {
        customer: CustomerType.isRequired,
        orderIncrementId: PropTypes.string,
        returnCreatedAt: PropTypes.string,
        isNewReturn: PropTypes.bool.isRequired
    };

    renderOrderIdBlock() {
        const {
            isNewReturn,
            orderIncrementId
        } = this.props;

        if (isNewReturn) {
            return null;
        }

        return (
            <p className='_nomargin'>
                <span className="_strong">
                    { __('Order ID:') }
                    { ' ' }
                </span>
                <span>
                    #
                    { orderIncrementId }
                </span>
            </p>
        );
    }

    renderCreatedAtBlock() {
        const {
            isNewReturn,
            returnCreatedAt
        } = this.props;

        if (isNewReturn) {
            return null;
        }

        return (
            <p className='_nomargin'>
                <span className="_strong">{ __('Created At: ') }</span>
                <span>{ returnCreatedAt }</span>
            </p>
        );
    }

    render() {
        const {
            customer: {
                firstname: customerFirstName,
                lastname: customerLastName,
                email: customer_email,
                orders: {
                    items: [
                        {
                            billing_address: {
                                firstname: orderFirstName,
                                lastname: orderLastName,
                                street,
                                city,
                                region,
                                postcode,
                                telephone
                            }
                        }
                    ]
                }
            }
        } = this.props;

        return (
            <div className='amrma-customer-information'>
                    <div className='amrma-customer-name'>
                        { this.renderOrderIdBlock() }
                        <div>
                            <span className="_strong">{ __('Customer name: ') }</span>
                            <span>{ `${customerFirstName} ${customerLastName}` }</span><br />
                            <span className="_strong">{ __('Email: ') }</span>
                            <span>{ customer_email }</span><br />
                            { this.renderCreatedAtBlock() }
                        </div>                        
                    </div>
                    <div className='amrma-customer-address'>
                        <div><span className="_strong"> { __('Customer Address:') } { ' ' } </span><br />
                        { `${orderFirstName} ${orderLastName}` }
                        <br />
                        { street }
                        <br />
                        { `${city}, ${region}, ${postcode}` }
                        <br />
                        T:
                        { ' ' }
                        { /* eslint-disable-next-line react/forbid-elements */ }
                        <a href={ `tel:${telephone}` }>{ telephone }</a></div>
                    </div>
            </div>
        );
    }
}

export default AmastyCustomerInfoComponent;
