/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import Popup from 'Component/Popup';
import history from 'Util/History';

import AmastyCustomerInfo from '../../component/AmastyCustomerInfo';
import AmastyOrderItem from '../../component/AmastyOrderItem';
import { URL_PREFIX } from '../../component/MyReturnsTab/MyReturnsTab.config';
import {
    AmRmaSettingsNewReturnType,
    CustomerType,
    OptionsType,
    OrderItemsType,
    PlaceholderOptionType,
    RefType,
    UploadedFilesType
} from '../../type/AmastyRMA.type';
import {
    getOrderItemId, getUrlPath
} from '../../util/Utils';
import {
    ATTRIBUTE_KEY,
    ATTRIBUTE_ORDER_ID,
    ID_POPUP_INFO
} from './AmastyNewReturn.config';

import './AmastyNewReturn.style';

/** @namespace Scandiweb/AmastyRma/Route/AmastyNewReturn/Component */
export class AmastyNewReturnComponent extends PureComponent {
    static defaultProps = {
        popupMessage: ''
    };

    static propTypes = {
        getAmRmaSettings: AmRmaSettingsNewReturnType.isRequired,
        customer: CustomerType.isRequired,
        orderItems: OrderItemsType.isRequired,
        uploadFiles: PropTypes.func.isRequired,
        deleteUploadedFile: PropTypes.func.isRequired,
        submitNewRequest: PropTypes.func.isRequired,
        getCustomFieldRef: PropTypes.func.isRequired,
        changeReturnOptionState: PropTypes.func.isRequired,
        changePolicyState: PropTypes.func.isRequired,
        changeOrderItemState: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        uploadedFiles: UploadedFilesType.isRequired,
        isSubmitButtonDisabled: PropTypes.bool.isRequired,
        isDeleteButtonDisabled: PropTypes.bool.isRequired,
        popupMessage: PropTypes.string,
        reasonsOptions: OptionsType.isRequired,
        conditionsOptions: OptionsType.isRequired,
        resolutionsOptions: OptionsType.isRequired,
        placeholderOption: PlaceholderOptionType.isRequired,
        commentElement: RefType.isRequired
    };

    renderCustomFieldsElements = ({ code, title }) => {
        const { getCustomFieldRef } = this.props;
        return (
            <div key={ code } className="amrma-input-block">
                <Field
                  type={ FIELD_TYPE.text }
                  attr={ {
                      className: 'amrma-field',
                      [ATTRIBUTE_KEY]: code
                  } }
                  label={ title }
                  elemRef={ getCustomFieldRef(code) }
                />
            </div>
        );
    };

    renderCustomFieldsBlock() {
        const {
            getAmRmaSettings: {
                customFields,
                customFieldsBlockTitle
            }
        } = this.props;

        if (!customFields?.length) {
            return null;
        }

        return (
            <div className="amrma-bank-details">
                <h2 className="amrma-title">
                    { customFieldsBlockTitle }
                </h2>
                { customFields.map(this.renderCustomFieldsElements) }
            </div>
        );
    }

    renderReturnPolicyBlock() {
        const {
            getAmRmaSettings: {
                isReturnPolicyEnabled,
                policyUrl
            },
            changePolicyState
        } = this.props;

        if (!isReturnPolicyEnabled) {
            return null;
        }

        return (
            <div className="amrma-return-policy">
                <div className="amrma-checkbox-container -policy">
                    <Field
                      type={ FIELD_TYPE.checkbox }
                      attr={ {
                          id: 'amrma-checkbox-policy',
                          className: 'amrma-checkbox'
                      } }
                      events={ {
                          onChange: changePolicyState
                      } }
                    />
                    <label htmlFor="amrma-checkbox-policy" className="amrma-text">
                        { __('I have read and accept the') }
                        { ' ' }
                        <Link to={ getUrlPath(policyUrl) } target="_blank" rel="noreferrer">
                            { ' ' }
                            { __('Return Policy') }
                            { ' ' }
                            <span className="amrma-return-policy-required">*</span>
                        </Link>
                    </label>
                </div>
            </div>
        );
    }

    renderOrderItemBlock = (orderItem, i) => {
        const {
            changeOrderItemState,
            changeReturnOptionState,
            placeholderOption,
            reasonsOptions,
            conditionsOptions,
            resolutionsOptions,
            getAmRmaSettings: {
                isShowAdministratorContact,
                administratorEmail,
                administratorPhoneNumber
            }
        } = this.props;

        return (
            <AmastyOrderItem
              id={ getOrderItemId(i) }
              index={ i }
              item={ orderItem }
              options={ {
                  placeholder: placeholderOption,
                  reasons: reasonsOptions,
                  conditions: conditionsOptions,
                  resolutions: resolutionsOptions
              } }
              settings={ {
                  isShowAdministratorContact,
                  administratorEmail,
                  administratorPhoneNumber
              } }
              onChange={ changeOrderItemState }
              onReturnOptionChange={ changeReturnOptionState }
            />
        );
    };

    renderCustomerInfo() {
        const {
            customer
        } = this.props;

        return (
            <AmastyCustomerInfo
              isNewReturn
              customer={ customer }
            />
        );
    }

    renderUploadedFileBlock = ({ filehash, filename }) => {
        const {
            deleteUploadedFile,
            isDeleteButtonDisabled
        } = this.props;

        return (
            <div id={ filehash } className="amrma-chat-file -newreturn">
                <span className="amrma-file">
                    { filename }
                </span>
                <span
                  className="amrma-file amrma-delete"
                  onClick={ deleteUploadedFile }
                  onKeyUp={ deleteUploadedFile }
                  disabled={ isDeleteButtonDisabled }
                  data-file-hash={ filehash }
                  role="button"
                  aria-label="Delete file"
                />
            </div>
        );
    };

    renderProducts() {
        const {
            orderItems,
            commentElement
        } = this.props;
console.log("renderProducts" );
        return (
            <div className="amrma-products-block">
                <h2 className="amrma-title">{ __('Choose Items') }</h2>
                <div className="amrma-products-table">
                    { orderItems.map(this.renderOrderItemBlock) }
                </div>
                { this.renderCustomFieldsBlock() }
                <div className="amrma-comment-block">
                    <h2 className="amrma-title">{ __('Message') }</h2>
                    <p className="_nomargin amrma-img-text">
                        { __(`Please do not forget to take a picture of the goods from all sides.
                            Request without such photos may not be approved`) }
                    </p>
                    <textarea
                      name="comment"
                      className="amrma-comment"
                      ref={ commentElement }
                      cols="30"
                      rows="7"
                      placeholder={ __(' Please, describe the issue in details.') }
                    />
                    { this.renderFileAttachmentBlock() }
                    { this.renderReturnPolicyBlock() }
                    { this.renderButtonsBlock() }
                </div>
            </div>
        );
    }

    renderFileAttachmentBlock() {
        const {
            uploadFiles,
            uploadedFiles
        } = this.props;

        return (
            <div className="amrma-attach-file" data-amrma-js="file-container">
                <label className="amrma-label" htmlFor="amrma-attach">
                    { __('Attach File') }
                </label>
                <input
                  type="file"
                  id="amrma-attach"
                  name="filesContents"
                  accept=".pdf,.png,.jpg,.gif"
                  className="amrma-attach"
                  onChange={ uploadFiles }
                  multiple
                  data-mage-init='{"validation":{}}'
                />
                { uploadedFiles.map(this.renderUploadedFileBlock) }
            </div>
        );
    }

    renderButtonsBlock() {
        return (
            <div className="amrma-btns-block">
                { this.renderSubmitButton() }
                { this.renderCancelLink() }
            </div>
        );
    }

    renderSubmitButton() {
        const {
            isSubmitButtonDisabled
        } = this.props;

        return (
            <button
              block="Button"
              mods={ { capitalize: true, shadow: true } }
              className="btn amreturn-btn"
              type="submit"
              disabled={ isSubmitButtonDisabled }
            >
                { __('Submit Request') }
            </button>
        );
    }

    renderCancelLink() {
        return (
            <Link
              block="Button"
              mods={ { capitalize: true, shadow: true } }
              to={ `/${URL_PREFIX}/account/history` }
              className="amrma-link-cancel"
            >
                { __('Cancel') }
            </Link>
        );
    }

    renderForm() {
        const {
            customer: {
                orders: {
                    items: [
                        {
                            increment_id
                        }
                    ]
                }
            },
            submitNewRequest
        } = this.props;

        return (
            <Form
              className="amrma-create-rma"
              onSubmit={ submitNewRequest }
              attr={ {
                  [ATTRIBUTE_ORDER_ID]: increment_id
              } }
            >
                { this.renderCustomerInfo() }
                { this.renderProducts() }
            </Form>
        );
    }

    renderInfoPopup() {
        const {
            popupMessage
        } = this.props;

        return (
            <Popup
              id={ ID_POPUP_INFO }
            >
                <p style={ { whiteSpace: 'pre-wrap' } }>
                    { popupMessage }
                </p>
            </Popup>
        );
    }

    renderContent() {
        const {
            customer: {
                orders: {
                    items: [
                        {
                            increment_id
                        }
                    ]
                }
            },
            orderItems
        } = this.props;

        if (!orderItems?.length) {
            return (
                <h2 className="amrma-title">{ __('There are no items to return.') }</h2>
            );
        }

        return (
            <div>
                <h1 className="amrma-header">
                    { __('New Return for Order #%s', increment_id) }
                </h1>
                { this.renderForm() }
                { this.renderInfoPopup() }
            </div>
        );
    }

    render() {
        const {
            isLoading,
            customer: {
                orders: {
                    items: orders
                } = {}
            } = {}
        } = this.props;

        if (isLoading) {
            return (
                <Loader isLoading />
            );
        }

        if (!orders?.length) {
            history.push(`/${URL_PREFIX}/account/history`);
        }

        return (
            <div block="AmastyNewReturn">
                { this.renderContent() }
            </div>
        );
    }
}

export default AmastyNewReturnComponent;
