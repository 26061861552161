/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import AmastyMoreInformation from '../../component/AmastyMoreInformation';

const addAmastyReturnInformation = (args, callback, instance) => {
    const {
        item: {
            product: {
                name
            }
        }
    } = instance.props;

    return (
        <>
            { callback.apply(instance, args) }
            <AmastyMoreInformation
              productName={ name }
            />
        </>
    );
};

export default {
    'Component/CartItem/Component': {
        'member-function': {
            renderTitle: addAmastyReturnInformation
        }
    }
};
