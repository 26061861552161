/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';

import { DirectionType } from 'Type/Direction.type';

import { RIGHT } from './ChevronIcon.config';

import './ChevronIcon.style';

/** @namespace Component/ChevronIcon/Component */
export class ChevronIcon extends PureComponent {
    static propTypes = {
        direction: DirectionType
    };

    static defaultProps = {
        direction: RIGHT
    };

    render() {
        const { direction } = this.props;

        return (
            <svg
              block="ChevronIcon"
              mods={ { direction } }
              width="18"
              height="18"
              viewBox="0 0 9 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M8.31411 8.49317L1.46942 0.506992C1.35033 0.369327 1.15344 0.363208 1.02948 0.496152C0.905519 0.628737 0.90126 0.848466 1.01977 0.987139L7.65845 8.73324L1.01974 16.4794C0.901231 16.6181 0.90549 16.8378 1.02945 16.9704C1.08963 17.0348 1.16739 17.0667 1.24457 17.0667C1.32661 17.0667 1.40802 17.0307 1.46939 16.9595L8.31411 8.97332C8.42898 8.83904 8.42898 8.62745 8.31411 8.49317Z"/>
            </svg>
        );
    }
}

export default ChevronIcon;
